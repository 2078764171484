import React, { useEffect, useState } from 'react';
import PhotoService from '../../services/photo-service';
import axios from "axios"
import { Navigate} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import {
    MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon
  } from 'mdb-react-ui-kit';

const ConnexionForm = () => {

  const navigate = useNavigate();

  const [password,setPassword] = useState("");
  const [email,setEmail] = useState('');
  const [image,setImage]= useState(null)



  const handlePassword = ( e) =>{

    
    
      setPassword(e.target.value);
      console.log(e.target.value);
      console.log(password , "Password");


   
  }



  const handleSubmit = (e) =>{
e.preventDefault()

console.log("soumission");
console.log(email);
console.log(password);


axios({
method : "post",
url : "http://localhost:5000/api/user/login",
data : {
email : email
, password : password
}


}).then((response) =>   console.log(response.data)


).then((response) => navigate("/adminLucia")).catch((err) =>{ console.log(err.response.data.errorLogin)


const erreur = document.getElementById('errorDisplay');
erreur.innerHTML = `<div> ${err.response.data.errorLogin}</div>` 
erreur.style.color = "red"
// setTimeout(() => {
//   erreur.style.display = "none"
// }, 3000);

}




)


  }


  
  
  // Récupération des photo
  // useEffect(() => {

  //   axios.get('http://localhost:5000/api/photo/')
  //   .then(function (response) {
  //     // handle success
  //     const a = response;
  //     console.log(a);
  //     setImage(a.data[13].imageUrl);
  //   })
  //   .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //   })
    

  // },[image])

 
  


    //Gestion du mot de passe//
  const handleRandomPassword = (e) => {
e.preventDefault()



let string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcd" + "efghijklmnopqrstuvwxyz0123456789@#$";
let newPass = "";




const randomPassword = () => {
    e.preventDefault()

    for( let i = 1;i <=10; i++){
    
        // console.log(Math.floor(Math.random() * i))
    const a = Math.floor(Math.random() * string.length)
    
      
       const b =  string.charAt(a)
       console.log(b)
       
       newPass += b;
       
     setTimeout(()=> {
      console.log(newPassword, "Nouveau mot de passe");
    
    
    },3000)
    
    }
    return newPass

}
 const newPassword = randomPassword()


 








  }
   useEffect(() => {
const payloads= {

  allan : "castard",
  func : function(){
   console.log("hello");
  }
}

payloads.func()

   },[])


    return (

      <form onSubmit={handleSubmit}> 
        <MDBContainer className="p-3 my-5 d-flex flex-column w-50" >
          
      <MDBInput wrapperClass='mb-4' label='Email address' id='form1' type='text' onChange={(e) =>setEmail(e.target.value)} value={email}/>
      <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' onChange={(e) =>setPassword(e.target.value)} value= {password}/>

      <div className="d-flex justify-content-between mx-3 mb-4">
        <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
        <div >
          <MDBBtn onClick={handleRandomPassword}>Mot de passe oublié</MDBBtn>
          </div>
        
      </div>

      <MDBBtn className="mb-4" >connecter</MDBBtn>

      <div className="text-center">
        <p>Not a member? <a href="#!">Register</a></p>
        <p>or sign up with:</p>

        <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='facebook-f' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='twitter' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='google' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='github' size="sm"/>
          </MDBBtn>

        </div>
      </div>
     
<div id='errorDisplay'></div>

    </MDBContainer>
    </form>
    
    
    );
};

export default ConnexionForm;