import {configureStore} from "@reduxjs/toolkit"

import pictureSlice  from "../feature/pictures.slice"
import homeReducer from "../feature/home.slice"


//ENTREPOT , Etat global
export default configureStore({

//picture represente le reducteur de pictureSlice ( Toute les fonctions et le state de pictureSlice dans le reducers sont réunis dans pictureSlice.reducer)
    reducer : {
        pictures : pictureSlice.reducer ,
        homePic :  homeReducer
    }
})