import React from "react";
import { NavLink } from "react-router-dom";
import "../../style/navbar.css";

const Navbar = () => {
  return (
    <>
      <nav className="navbar-navbar navbar-expand-lg navbar-dark bg-dark">
        <NavLink to="/">
          <span className="nav-item nav-link">Logo</span>{" "}
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-item nav-link active navbarSize" href="#">
              Accueil <span className="sr-only navbarSize">(current)</span>
            </a>
            <a className="nav-item nav-link navbarSize" href="#">
              Informations
            </a>
            <a className="nav-item nav-link navbarSize" href="#contact">
              Réservation
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
