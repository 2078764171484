import React, { Suspense } from 'react';
import ConnexionForm from '../components/log/ConnexionForm';
import { connect } from 'react-redux'

const Login = () => {
    return (
       
       <ConnexionForm/>
       
    );
};

export default Login;