import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../App";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setPicturesData } from "../feature/pictures.slice";
import "../style/home.css";
import Navbar from "../components/nav/Navbar";
import Nav from "../components/nav/Nav";

const Homes = () => {
  const [titleText, setTitleText] = useState("");
  const dispatch = useDispatch();
  const picsData = useSelector((state) => state.pictures);
  const [topImg, setTopImg] = useState([]);
  const { username } = useContext(AppContext);
  const { setUsername } = useContext(AppContext);
  const [newName, setNewName] = useState("");

  const handleLog = () => {
    axios
      .get("https://act7-back.onrender.com/yo")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  // console.log(picsData.pictures);

  //Récupération des photo

  // useEffect(() => {
  //   async function fetMyapi() {
  //     let response = await fetch("http://localhost:5000/api/photo/");
  //     response = await response.json();
  //     console.log(response);
  //     setTopImg(response);
  //     console.log("okay");
  //   }
  //   fetMyapi();
  // }, []);

  // console.log(topImg);
  // let tab = [];

  // for (let value of Object.entries(topImg)) {
  //   tab.push(value[1].imageUrl);
  // }

  // console.log(tab[1]);

  return (
    <>
      <nav id="navbar" className="navbar">
        <ul class="nav-menu">
          <li>
            <a data-scroll="home" href="#home" className="dot active">
              <span>Accueil</span>
            </a>
          </li>
          <li>
            <a data-scroll="about" href="#about" className="dot">
              <span>À propos</span>
            </a>
          </li>
          <li>
            <a data-scroll="services" href="#services" className="dot">
              <span>Services</span>
            </a>
          </li>
          <li>
            <a data-scroll="work" href="#work" className="dot">
              <span>Gallerie</span>
            </a>
          </li>

          <li>
            <a data-scroll="contact" href="#contact" className="dot">
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </nav>
      {/* <Navbar /> */}
      <section
        id="home"
        className="hero-wrap js-fullheight"
        // style={{ backgroundImage: tab !== null || tab !== undefined ? `url( ${tab[1]})`  : null }}
        style={{ backgroundImage: `url(assets/images/estelle.jpg)` }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div className="col-lg-7 ftco-animate d-flex align-items-center">
              <div className="text text-center">
                <h1 className="logo">
                  <a href="index.html">
                    {/* <span className="flaticon-camera-shutter"></span>*/}
                    ACT 7<small>Île de la Réunion</small>
                  </a>
                </h1>
                <h1 className="mb-4">{/* {titleText} */}</h1>
                <h4 className="mb-4 text-size ft">
                  Créateur de contenu audiovisuel pour les professionnels et les
                  particuliers.
                  {/* ACT 7 est une agence de production audiovisuel
                  et photographique indépendante, basée à La Réunion. */}
                </h4>
                <div onClick={handleLog}>
                  <p className="mt-5">
                    <a
                      href="#about"
                      className="btn-custom"
                      style={{ fontSize: "15px" }}
                    >
                      Découvrir{" "}
                      <span className="ion-ios-arrow-round-forward"></span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-about img ftco-section" id="about">
        <div class="container">
          <div class="row d-flex no-gutters">
            <div class="col-md-6 col-lg-6 d-flex">
              <div class="img-about img d-flex align-items-stretch">
                <div class="overlay"></div>
                <div
                  class="img img-video d-flex align-self-stretch align-items-center"
                  style={{
                    backgroundImage: `url(assets/images/ivanaelle-min.jpg)`,
                  }}
                >
                  <div class="video justify-content-center">
                    <a
                      href="https://vimeo.com/45830194"
                      class="icon-video popup-vimeo d-flex justify-content-center align-items-center"
                    >
                      <span class="ion-ios-play"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 pl-md-5">
              <div class="heading-section ftco-animate">
                <h2 class="mb-4">
                  Act 7 spécialisé dans la création de contenue photo et vidéo.
                </h2>
                <p>
                  Je suis Lucia Tauran, directrice d'Act 7 et photographe
                  portraitiste à l'île de la Réunion.
                  <br />
                  J'immortalise les évènements majeurs de votre vie à la hauteur
                  de vos envies.
                  <br />
                  Je m'engage également auprès des professionnels afin de
                  répondre à leurs besoins en communication et de les
                  accompagner au développement de leur image de marque
                </p>

                <div class="counter-wrap ftco-animate d-flex my-md-4">
                  <div class="text">
                    <p class="mb-4">
                      <span class="number" data-number="65">
                        65
                      </span>
                      <span> Projets réalisés</span>
                    </p>
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div
                    class="img img-about-2 mr-2"
                    // style={{
                    //   backgroundImage: `url(assets/images/about.jpg)`,
                    // }}
                  ></div>
                  <div
                    class="img img-about-2 ml-2"
                    // style={{
                    //   backgroundImage: `url(assets/images/about-3.jpg)`,
                    // }}
                  ></div>
                </div>
                <blockquote class="blockquote mt-5">
                  <p class="mb-2">
                    "Creativity is a wild mind and a disciplined eye.."
                  </p>
                  {/* <span>&mdash; Lucy Lee</span> */}
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" class="ftco-section">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-5 heading-section ftco-animate pb-5">
                  <h2 class="mb-4">Service photographie</h2>
                  <p>
                    Besoin d'un photographe professionnel pour une séeance photo
                    à l'île de la Réunion ?
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="media block-6 services d-block ftco-animate">
                    <div class="icon">
                      <span class="flaticon-video-camera"></span>
                    </div>
                    <div class="media-body">
                      <h3 class="heading mb-3">Portrait</h3>
                      <p>
                        Que vous soyez seul, en couple, en famille, je capture
                        des portraits intemporels au style adapté à vos diverses
                        attentes.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="media block-6 services d-block ftco-animate">
                    <div class="icon">
                      <span class="flaticon-video-player"></span>
                    </div>
                    <div class="media-body">
                      <h3 class="heading mb-3">ÉVÈNEMENTS</h3>
                      <p>
                        Baptême, Mariage, EVJF, Anniversaire, immortaliser les
                        émotions et moments clés de votre JOUR-J.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="media block-6 services d-block ftco-animate">
                    <div class="icon">
                      <span class="flaticon-video-player-1"></span>
                    </div>
                    <div class="media-body">
                      <h3 class="heading mb-3">CORPORATE</h3>
                      <p>
                        Je réalise pour les entreprises des reportages photos et
                        vidéos afin de mettre en valeur leur savoir-faire et
                        Leurs produits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-stretch">
              <div
                class="img w-100"
                style={{ backgroundImage: `url(assets/images/about.jpg)` }}
              ></div>
            </div>
          </div>
          <div class="row progress-circle pt-md-5">
            <div class="col-md-7 order-md-last py-md-5">
              <div class="row">
                <div class="col-md-4 mb-md-0 mb-4 ftco-animate">
                  <div class="">
                    <h2 class="text-center mb-4">Création</h2>

                    <div class="progress mx-auto" data-value="90">
                      <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="h5">
                          90<sup class="small">%</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-md-0 mb-4 ftco-animate">
                  <div class="">
                    <h2 class="text-center mb-4">Développement</h2>

                    <div class="progress mx-auto" data-value="80">
                      <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="h5">
                          80<sup class="small">%</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-md-0 mb-4 ftco-animate">
                  <div class="">
                    <h2 class="text-center mb-4">Production</h2>

                    <div class="progress mx-auto" data-value="75">
                      <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
                      </span>
                      <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="h5">
                          75<sup class="small">%</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-md-4 mt-4">
                  <div class="col-md-8 ftco-animate">
                    {/* <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 d-flex align-items-stretch">
              <div
                class="img w-100"
                style={{
                  backgroundImage: `url(assets/images/about-2.jpg)`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section id="work" class="ftco-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-4 heading-section text-center ftco-animate pb-5">
              <h2 class="mb-4">Projets réalisés</h2>
              {/* <p>Phrase de présentation</p> */}
            </div>
          </div>
        </div>
        <div class="container-fluid px-md-0">
          <div class="row no-gutters">
            <div class="col-md-4 ftco-animate">
              <div
                className="quentin-pic"
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/quentin.jpg)`,
                }}
              >
                <a
                  href="images/model-1.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Quentin Boucher</span>
                    <h2>
                      <a href="work-single.html">Mister Jeunesse France 2023</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/ivanaelle.jpg)`,
                }}
              >
                <a
                  href="images/model-2.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Ivaanaelle </span>
                    <h2>
                      <a href="work-single.html">Modele</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/estelle1.jpg)`,
                }}
              >
                <a
                  href="images/model-3.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Estelle</span>
                    <h2>
                      <a href="work-single.html">Comédienne</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{ backgroundImage: `url(assets/images/violon.jpg)` }}
              >
                <a
                  href="images/model-4.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Contrebassiste</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{ backgroundImage: `url(assets/images/roolio.jpg)` }}
              >
                <a
                  href="images/model-5.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nahyl</span>
                    <h2>
                      <a href="work-single.html">Artiste</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-5.jpg)`,
                }}
              >
                <a class="icon image-popup d-flex justify-content-center align-items-center">
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Klyana</span>
                    <h2>
                      <a href="work-single.html">Modele</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-7.jpg)`,
                }}
              >
                <a
                  href="images/model-7.jpg"
                  className="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{ backgroundImage: `url(assets/images/model-8jpg)` }}
              >
                <a
                  href="images/model-8.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-9.jpg)`,
                }}
              >
                <a
                  href="images/model-9.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-10.jpg)`,
                }}
              >
                <a
                  href="images/model-10.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-11.jpg)`,
                }}
              >
                <a
                  href="images/model-11.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="col-md-4 ftco-animate">
              <div
                class="model img d-flex align-items-end"
                style={{
                  backgroundImage: `url(assets/images/model-12.jpg)`,
                }}
              >
                <a
                  href="images/model-12.jpg"
                  class="icon image-popup d-flex justify-content-center align-items-center"
                >
                  <span class="icon-expand"></span>
                </a>
                <div class="desc w-100 px-4">
                  <div class="text w-100 mb-3">
                    <span>Nature</span>
                    <h2>
                      <a href="work-single.html">Beautiful Work</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section class="ftco-section contact-section" id="contact">
        <div class="container">
          <div class="row justify-content-center mb-5 pb-3">
            <div class="col-md-4 heading-section text-center ftco-animate">
              <h2 class="mb-4">Contact </h2>
              <p>Je reste a votre dispostion pour toute demandes</p>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-3 d-flex ftco-animate">
              {/* <div class="align-self-stretch box text-center p-4">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="icon-map-signs"></span>
                  </div>

                </div> */}
            </div>
            <div class="col-md-3 d-flex ftco-animate">
              <div class="align-self-stretch box text-center p-4">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="icon-phone2"></span>
                </div>
                <div>
                  <h3 class="mb-4">Numéro</h3>
                  <p>
                    <a href="tel://1234567920">+262 693 85 37 67</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex ftco-animate">
              <div class="align-self-stretch box text-center p-4">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="icon-paper-plane"></span>
                </div>
                <div>
                  <h3 class="mb-4">Email </h3>
                  <p>
                    <a href="mailto:info@yoursite.com">
                      contact@act7production.fr
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* <div class="col-md-3 d-flex ftco-animate">
                <div class="align-self-stretch box text-center p-4">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <span class="icon-globe"></span>
                  </div>
                  <div>
                    <h3 class="mb-4">Website</h3>
                    <p>
                      <a href="#">yoursite.com</a>
                    </p>
                  </div>
                </div>
              </div> */}
          </div>

          <div class="row block-9 justify-content-center">
            <div class="col-md-6 ftco-animate">
              <form action="#" class="contact-form p-4 p-md-5 py-md-5">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Votre Nom"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Votre Email"
                  />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Sujet" />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    class="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Envoyer"
                    class="btn btn-primary py-3 px-5"
                  />
                </div>
              </form>
            </div>

            {/* <div class="col-md-6 d-flex align-items-stretch .contact-section .">
                <div id="map" class="model img d-flex align-items-end"
                  style={{ backgroundImage: `url(assets/images/model-7.jpg)` }}>


                </div>
              </div> */}
          </div>
        </div>
      </section>

      <footer class="ftco-footer py-5">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                Made by CastardCorp
                {/* <i class="icon-heart" aria-hidden="true"></i> by{" "}
                  <a href="https://colorlib.com" target="_blank">
                    Colorlib
                  </a> */}
              </p>
              <ul class="ftco-footer-social p-0">
                <li class="ftco-animate">
                  <a href="https://www.facebook.com/act7production/">
                    <span class="icon-facebook"></span>
                  </a>
                </li>
                <li class="ftco-animate">
                  <a href="https://www.instagram.com/actmywedding/">
                    <span class="icon-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* <div id="ftco-loader" class="show fullscreen">
        <svg class="circular" width="48px" height="48px">
          <circle
            class="path-bg"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke-width="4"
            stroke="#eeeeee"
          />
          <circle
            class="path"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke="#F96D00"
          />
        </svg>
      </div> */}
    </>
  );
};

export default Homes;
