import { createSlice } from "@reduxjs/toolkit";

//Je crée une tranche
export const pictureSlice = createSlice({
  //création de mon state
  name: "pictures",
  initialState: {
    pictures: null,
  },

  //le reducers contient toute les actions
  reducers: {
    setPicturesData: (state, action) => {
      state.pictures = action.payload;
    },

    deletePictures: () => {},

    addPic: () => {},
  },
});

// console.log(pictureSlice);
//export la methode pour qu'elle soit accessible partout. Les méthodes sont contenus dans actions (reducers)
export const { setPicturesData } = pictureSlice.actions;
export default pictureSlice;
