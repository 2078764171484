import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Navbar from '../components/nav/Navbar';
import axios from 'axios';

const Administrateur = () => {

    const [topImg,setTopImg]= useState();
    const [text,setText] = useState();
    const [file,setFile] = useState()
    const [test,setTest]= useState(null)


    useEffect(() => {


        async function fetMyapi(){  
        let response = await fetch("http://localhost:5000/api/photo/");
        response = await response.json();
     
         setTopImg(response);
  
        }
        fetMyapi()
          },[]);


          

//Gestion Formulaire image
const handleForm = (e)=> {

e.preventDefault()
console.log("okay");

const data = new FormData();

data.append("sampleFile", file);

axios({
  method: "post",
  url: `http://localhost:5000/api/photo/photos`,
  data : data,

})
.then(function (response) {
  console.log(response);
})
.catch(function (error) {
  console.log(error);
});

}


    return (
        <>
        <div className='color'> 
     
        <div className='background'> 
        <Navbar/>
       {/* <Card/> */}
  
  {/* {topImg ?
  
  
  <ul className="image-list">
  
  
    
  
    <li><img src={topImg.at(0).imageUrl}/></li>
  </ul>
  : <div> <h2 style={{color:"black"}}>chargement...</h2> </div>}
   */}
       </div>
       <form action="" className="container-pic" encType="multipart/form-data" method="post" onSubmit= {handleForm}>
      <div className="flex">
        <label htmlFor="file">Changer d'image</label>
  
     
         <input type="file" class="form-control-file" name="sampleFile"     accept=".jpg, .jpeg, .png" multiple onChange={(e) => {setFile(e.target.files[0])}}/>
        {/* <input type="text" name="text"  onChange={(e) => {setText(e.target.value)}}/> */}
        <br />
        <input type="submit" value="Envoyer" />
      </div>
      </form>

      
       </div></>
    );
};

export default Administrateur;