import "./App.css";

import Homes from "./pages/Homes";
import Login from "./pages/Login";
import Profil from "./pages/Profil";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setPicturesData } from "./feature/pictures.slice";
import axios from "axios";
import { useEffect, createContext, useState } from "react";
import Administrateur from "./pages/Administrateur";
export const AppContext = createContext();

function App() {
  const dispatch = useDispatch();

  // // Récupération des photo
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:5000/api/photo/")
  //     .then((res) => dispatch(setPicturesData(res.data)))
  //     .catch((err) => console.log("ERROR GET PHOTOS"));
  // }, []);

  const [username, setUsername] = useState("");
  return (
    <AppContext.Provider value={{ username, setUsername }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homes />}></Route>
          <Route path="*" element={<Homes />}>
            {" "}
          </Route>
          <Route path="/log" element={<Login />}></Route>
          <Route path="/adminLucia" element={<Administrateur />}></Route>
          <Route path="/profil" element={<Profil />}>
            {" "}
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
