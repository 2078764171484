import React from "react";
import { useContext, useState } from "react";
import { AppContext } from "../App";

const Profil = () => {
  const { username } = useContext(AppContext);
  const { setUsername } = useContext(AppContext);
  const [newName, setNewName] = useState("");

  console.log(username);

  return (
    <>
      {" "}
      <div>profil {username}</div>
      <input type="text" onChange={(e) => setNewName(e.target.value)} />
      <button className="btn btn-primary" onClick={() => setUsername(newName)}>
        Change username
      </button>{" "}
    </>
  );
};

export default Profil;
