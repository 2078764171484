import { createSlice } from "@reduxjs/toolkit";

export const homepicSlice = createSlice({
name : "homePic",
initialState : {
homePic : null

},

reducers : {

setHomePic : (state,action) => {


    state.homePic = action.payload;
}


}

})

export const {setHomePic} = homepicSlice.actions
export default homepicSlice.reducer